import { useTheme } from "@emotion/react";
import { Button, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery } from "@mui/material";
import LoadingButton from "components/@extended/LoadingButton";
import { GlobalFilter } from "components/@extended/Table/ReactTableFilter";
import MainCard from "components/MainCard";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ApiService from "service/ApiService";
import { dispatch } from "store";
import { openSnackbar } from "store/reducers/snackbar";
import utils from "utils/utils";
import * as Yup from 'yup';


const itemPriceSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    itemPrice: Yup.array().of(
        Yup.object().shape({
            price: Yup.string().nullable()
                .test(
                    "non-negative",
                    "Price must be a non-negative or greater then 0 number.",
                    (value) => {
                        if (!value || value == null) return true;
                        const numValue = Number(value);
                        return numValue >= 0;
                    }
                ),
        })
    ),
});

function calculatePercentage(value, percentage, type) {
    let percentageDecimal = parseFloat(percentage) / 100;
    return type === '+' ? value * (1 + percentageDecimal) : value * (1 - percentageDecimal);
}

const ModifierPriceSetUp = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const location = useLocation();
    const [price, setPrice] = useState('');
    const menuData = location.state ? location.state : null;

    const getModifierPriceList = async () => {
        setIsLoading(true);
        const { data } = await ApiService.getModifierPriceAsync((menuData && menuData.menuId) ? menuData.menuId : '');
        if (data) {
            formik.setFieldValue('itemPrice', data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (menuData) {
            formik.setFieldValue('menuId', menuData.menuId);
            formik.setFieldValue('name', menuData.name);
            formik.setFieldValue('description', menuData.description)
        }
        getModifierPriceList();
    }, [])


    const formik = useFormik({
        initialValues: {
            itemPrice: [],
            menuId: '',
            name: '',
            description: ''
        },
        validationSchema: itemPriceSchema,
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            setSubmitting(true);
            const updatedPrice = values.itemPrice.filter((item) => item.price && Number(item.price) > 0).map((item) => {
                return { ...item, price: Number(item.price) }
            })

            const payload = {
                menuId: values.menuId,
                itemPrices: updatedPrice,
            }

            const { data, error } = await ApiService.saveModifierPriceAsync(payload, (menuData && menuData.type) ? menuData.type : '');
            if (data) {
                showTosterMessage("modifier price is updated successfully.")
                navigate(-1)
            } else if (error) {
                setErrors(error);
            }
            setSubmitting(false)
        }
    })

    const handleIncreasePrice = (values, price) => {
        const updatedItemPrice = values.itemPrice.map((item) => (
            {
                ...item,
                price: calculatePercentage(Number(item.price), price, '+').toFixed(2),
            }
        ));
        formik.setFieldValue('itemPrice', updatedItemPrice);
        setPrice('');
    };

    const handleDecreasePrice = (values, price) => {
        const updatedItemPrice = values.itemPrice.map((item) => (
            {
                ...item,
                price: calculatePercentage(Number(item.price), price, '-').toFixed(2)
            }
        ));
        formik.setFieldValue('itemPrice', updatedItemPrice);
        setPrice('');
    };

    const showTosterMessage = (message) => {
        dispatch(
            openSnackbar({
                open: true,
                message: message,
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: true
            })
        );
    }


    return (
        <Grid container rowSpacing={3} columnSpacing={1}>
            <Grid item xs={12}>
                <FormikProvider value={formik}>
                    <Form autoComplete='off' noValidate onSubmit={formik.handleSubmit}>
                        <Grid item xs={12} pb={2}>
                            <Stack direction={'row'} justifyContent={'space-between'} py={1}>
                                <Typography variant="h2">Modifier Pricing Setup</Typography>
                                <Button variant='contained' size='medium' onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Stack>
                            <MainCard content={false}>
                                <Grid
                                    container
                                    direction={matchDownSM ? 'column' : 'row'}
                                    justifyContent='space-between'
                                    alignItems='center'
                                    spacing={1}
                                    sx={{ py: 3, px: 3 }}
                                >
                                    <Grid item xs={12} sm={4}>
                                        <InputLabel sx={{ pb: 1 }}>Name</InputLabel>
                                        <Stack spacing={1}>
                                            <FormControl>
                                                <TextField
                                                    fullWidth
                                                    id='name'
                                                    name='name'
                                                    placeholder='Enter Name'
                                                    disabled={true}
                                                    value={formik.values.name}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <InputLabel sx={{ pb: 1 }}>Description</InputLabel>
                                        <Stack spacing={1}>
                                            <FormControl>
                                                <TextField
                                                    fullWidth
                                                    id='description'
                                                    name='description'
                                                    placeholder='Enter description'
                                                    disabled={true}
                                                    value={formik.values.description} />
                                            </FormControl>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={4} >
                                        <Stack
                                            direction={matchDownSM ? 'column' : 'row'}
                                            justifyContent={matchDownSM ? 'flex-start' : 'flex-end'}
                                            alignItems='center'
                                            pt={2}
                                            spacing={1}
                                        >
                                            <LoadingButton
                                                loading={formik.isSubmitting}
                                                type='submit'
                                                variant='contained'
                                            >
                                                Save pricing
                                            </LoadingButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                        {isLoading ? <Stack
                            justifyContent="center"
                            alignItems="center"
                            style={{ height: '50vh', width: '90vw' }}>
                            <CircularProgress />
                        </Stack > : <>
                            <Grid item xs={12}>
                                <MainCard content={false}>
                                    {formik.values.itemPrice.length > 0 ? <>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column' : 'row'}
                                            justifyContent='space-between'
                                            alignItems='center'
                                            sx={{ py: 3, px: 3 }}
                                        >
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Stack direction='row' alignItems='center' spacing={1}>
                                                    <GlobalFilter
                                                        preGlobalFilteredRows={formik.values.itemPrice}
                                                        globalFilter={globalFilter}
                                                        setGlobalFilter={setGlobalFilter}
                                                        size='large'
                                                        sx={{ width: '100%' }}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Stack
                                                    direction={matchDownSM ? 'column' : 'row'}
                                                    justifyContent={matchDownSM ? 'flex-start' : 'flex-end'}
                                                    alignItems='center'
                                                    spacing={1}
                                                >
                                                    <Button variant="contained" onClick={() => handleDecreasePrice(formik.values, price)} disabled={!price}>-</Button>
                                                    <FormControl>
                                                        <FormControl sx={{ m: 1, width: '25ch' }}>
                                                            <OutlinedInput
                                                                id='price'
                                                                name='price'
                                                                value={price}
                                                                placeholder="Enter price"
                                                                onChange={(e) => setPrice(e.target.value)}
                                                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                            />
                                                        </FormControl>
                                                    </FormControl>
                                                    <Button variant="contained" onClick={() => handleIncreasePrice(formik.values, price)} disabled={!price}>+</Button>
                                                </Stack>
                                            </Grid>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer sx={{ maxHeight: `calc(100vh - 42vh)`, overflowY: 'auto' }} >
                                                <Table stickyHeader sx={{ minWidth: 650 }} >
                                                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
                                                        <TableRow >
                                                            <TableCell>Name</TableCell>
                                                            <TableCell sx={{ pl: 3 }}>Price</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <FieldArray
                                                            name='itemPrice'
                                                            render={() => formik.values.itemPrice.map((row, index) => {
                                                                return (
                                                                    <TableRow
                                                                        key={`${row.name}_${index}`}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row">
                                                                            {row.name}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <FormControl error={formik.touched.itemPrice?.[index]?.price && Boolean(formik.errors.itemPrice?.[index]?.price)}>
                                                                                <OutlinedInput
                                                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                                    id={`itemPrice.${index}.price`}
                                                                                    name={`itemPrice.${index}.price`}
                                                                                    placeholder='Enter price'
                                                                                    value={row.price || ''}
                                                                                    inputProps={{ maxLength: 5 }}
                                                                                    onKeyDown={utils.handleNumericKey}
                                                                                    onChange={(e) => {
                                                                                        if (parseFloat(e.target.value) >= 0 || e.target.value === "") {
                                                                                            formik.setFieldValue(`itemPrice.${index}.price`, e.target.value);
                                                                                        }

                                                                                    }}
                                                                                />

                                                                                <FormHelperText error={formik.touched.itemPrice?.[index]?.price && Boolean(formik.errors.itemPrice?.[index]?.price)} sx={{ ml: 0 }}>
                                                                                    {formik.touched.itemPrice?.[index]?.price && formik.errors.itemPrice?.[index]?.price}
                                                                                </FormHelperText>
                                                                            </FormControl>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                        />
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </>
                                        :
                                        <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: '50vh' }}>
                                            <Typography color='primary' variant='h5' align='center'>
                                                No records found.
                                            </Typography>
                                        </Stack>}
                                </MainCard>
                            </Grid ></>}
                    </Form>
                </FormikProvider>
            </Grid>
        </Grid >
    )
}


export default ModifierPriceSetUp;