// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { AutoAwesomeMotionOutlined, BurstModeOutlined, CategoryOutlined, DoDisturbOutlined, EditNoteOutlined, FastfoodOutlined, Filter, MenuOutlined, PriceChange } from '@mui/icons-material';
import enums from 'utils/enums';

// icons
const icons = {
  MenuOutlined,
  CategoryOutlined,
  BurstModeOutlined,
  DoDisturbOutlined,
  EditNoteOutlined,
  Filter,
  AutoAwesomeMotionOutlined,
  PriceChange,
  FastfoodOutlined,
};

// ==============================|| MENU ITEMS - MAIN ||============================== //
const menu = {
  id: 'mobile-application',
  type: 'group',
  title: <FormattedMessage id='application' />,
  roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin],
  children: [
    {
      id: 'banners',
      title: <FormattedMessage id='banners' />,
      subTitle: 'Manage Banners',
      type: 'item',
      url: 'mobile-application/banners',
      icon: icons.BurstModeOutlined,
      roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin],
      isShowFilter: true,
      showTitle: true,
    },
    {
      id: 'screensavers',
      title: <FormattedMessage id='screensavers' />,
      subTitle: 'Manage Screen Savers',
      type: 'item',
      url: 'mobile-application/screensavers',
      icon: icons.Filter,
      roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin],
      isShowFilter: true,
      showTitle: true,
    },
    {
      id: 'submenulist',
      title: <FormattedMessage id='submenulist' />,
      subTitle: 'Manage Categories',
      type: 'item',
      url: 'mobile-application/submenu',
      icon: icons.CategoryOutlined,
      roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin],
      isShowFilter: true,
      showTitle: true,
    },
    {
      id: 'menulist',
      title: <FormattedMessage id='menulist' />,
      subTitle: 'Manage Menu Items',
      type: 'item',
      url: 'mobile-application/menu',
      icon: icons.MenuOutlined,
      roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin],
      isShowFilter: true,
      showTitle: false,
    },
    {
      id: 'modifieritemlist',
      title: <FormattedMessage id='modifierlist' />,
      subTitle: 'Manage Modifiers',
      type: 'item',
      url: 'mobile-application/modifieritems',
      icon: icons.EditNoteOutlined,
      roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin],
      isShowFilter: true,
      showTitle: true,
    },
    {
      id: 'suggestiveItems',
      title: <FormattedMessage id='suggestiveItems' />,
      subTitle: 'Manage Suggestive Items',
      type: 'item',
      url: 'mobile-application/suggestiveItems',
      roleTags: [enums.userRole.SuperAdmin, enums.userRole.CJAdmin],
      icon: icons.AutoAwesomeMotionOutlined,
      isShowFilter: true,
      showTitle: true,
    },
    {
      id: 'pricingSetup',
      title: <FormattedMessage id='pricingSetup' />,
      type: 'item',
      url: 'mobile-application/price-setup',
      roleTags: [enums.userRole.SuperAdmin, enums.userRole.CJAdmin],
      icon: icons.PriceChange,
      isShowFilter: true,
      showTitle: true,
      isExternal: true
    },
    {
      id: 'comboTemplate',
      title: <FormattedMessage id='comboTemplate' />,
      type: 'item',
      url: 'mobile-application/templates',
      roleTags: [enums.userRole.SuperAdmin, enums.userRole.CJAdmin],
      icon: icons.FastfoodOutlined,
      isShowFilter: true,
      showTitle: true,
      isExternal: true
    },
  ],
};

export default menu;
