// material-ui
import { Box, FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';

// assets
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => {
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            navigate(`/customer/orders`, { state: { phone: searchValue } });
        }
    };

    return (
        <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
            <FormControl sx={{ width: { xs: '100%', md: 350 } }}>
                <OutlinedInput
                    fullWidth
                    size="small"
                    id="header-search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    sx={{ backgroundColor: 'white' }}
                    startAdornment={
                        <InputAdornment position="start" sx={{ color: 'black' }}>
                            <SearchOutlined />
                        </InputAdornment>
                    }
                    endAdornment={
                        searchValue && (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={() => setSearchValue('')}>
                                    <CloseOutlined />
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                    aria-describedby="header-search-text"
                    placeholder="Search Customer By Phone"
                />
            </FormControl>
        </Box>
    )
};

export default Search;
