import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Divider, List, Typography } from '@mui/material';

// project import
import NavItem from './NavItem';
import NavCollapse from './NavCollapse';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const theme = useTheme();
  const menu = useSelector((state) => state.menu);
  const { user } = useSelector((state) => state.auth);
  const { flavour } = useSelector((state) => state.auth);

  const { drawerOpen } = menu;

  const navCollapse = item.children?.map((menuItem) => {    
    menuItem.isHide = flavour.isExternal === menuItem.isExternal;
    if ((!menuItem.roleTags || menuItem.roleTags.includes(user.role))) {
      switch (menuItem.type) {
        case 'collapse':
          return <NavCollapse key={menuItem.id} menu={menuItem} level={1} />;
        case 'item':
          return <NavItem key={menuItem.id} item={menuItem} level={1} />;
        default:
          return (
            <Typography key={menuItem.id} variant='h6' color='error' align='center'>
              Fix - Group Collapse or Items
            </Typography>
          );
      }
    }
  });

  return (
    <>
      <List
        subheader={
          item.title &&
          drawerOpen && (
            <Box sx={{ pl: 3, mb: 1.5 }}>
              <Typography variant='subtitle2' color={theme.palette.mode === 'dark' ? 'textSecondary' : 'text.secondary'}>
                {item.title}
              </Typography>
              {item.caption && item.isExternal === flavour.isExternal && (
                <Typography variant='caption' color='secondary'>
                  {item.caption}
                </Typography>
              )}
            </Box>
          )
        }
        sx={{ mt: drawerOpen && item.title ? 1.5 : 0, py: 0, zIndex: 0 }}
      >
        {navCollapse}
      </List>
      <Box sx={{ py: 1 }}>
        <Divider />
      </Box>
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
